@keyframes bounce {
  0%,
  100% {
    transform: translateY(-15%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bounce {
  animation: bounce 1s infinite;
}

.custom-shape-divider-bottom-1731492067 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1731492067 svg {
  position: relative;
  display: block;
  width: calc(130% + 1.3px);
  height: 70px;
}

.custom-shape-divider-bottom-1731492067 .shape-fill {
  fill: #fafafa;
}
.custom-shape-divider-bottom-1731492448 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1731492448 svg {
  position: relative;
  display: block;
  width: calc(130% + 1.3px);
  height: 70px;
}

.custom-shape-divider-bottom-1731492448 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-top-1731313274 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1731313274 svg {
  position: relative;
  display: block;
  width: calc(120% + 1.3px);
  height: 46px;
}

.custom-shape-divider-top-1731313274 .shape-fill {
  fill: #f5f5f5;
}

.custom-shape-divider-bottom-1731313542 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1731313542 svg {
  position: relative;
  display: block;
  width: calc(120% + 1.3px);
  height: 46px;
}

.custom-shape-divider-bottom-1731313542 .shape-fill {
  fill: #f5f5f5;
}

.react-flow {
  --bg-color: #fff;
  --text-color: #000;
  --node-border-radius: 10px;
  --node-box-shadow: 5px 0 25px rgba(241, 135, 5, 0.3),
    -5px 0 25px rgba(255, 255, 255, 0.2);
  background-color: var(--bg-color);
  color: var(--text-color);
}

.react-flow__node-custom {
  border-radius: var(--node-border-radius);
  display: flex;
  height: 70px;
  min-width: 150px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  pointer-events: none;
  box-shadow: var(--node-box-shadow);
}

.react-flow__node-custom .wrapper {
  overflow: hidden;
  display: flex;
  padding: 2px;
  position: relative;
  border-radius: var(--node-border-radius);
  flex-grow: 1;
}

.gradient:before {
  content: "";
  position: absolute;
  padding-bottom: calc(100% * 1.41421356237);
  width: calc(100% * 1.41421356237);
  background: conic-gradient(
    from 0deg at 50% 50%,
    #ff8c00 0deg,
    #f69e1a 120deg,
    rgb(255, 255, 255) 240deg,
    rgba(241, 135, 5, 0.795) 360deg
  );
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.react-flow__node-custom.selected .wrapper.gradient:before {
  content: "";
  background: conic-gradient(
    from 0deg at 50% 50%,
    #ff8c00 0deg,
    #f69e1a 120deg,
    rgb(255, 255, 255) 240deg,
    rgba(241, 135, 5, 0.795) 360deg
  );
  animation: spinner 1.5s ease-in-out infinite;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

/* Only animate the cloud container, not the icon */
.react-flow__node-custom.selected .cloud {
  animation: cloudPulse 1.5s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes cloudPulse {
  0% {
    transform: translate(50%, -50%) scale(1);
  }
  50% {
    transform: translate(50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(50%, -50%) scale(1);
  }
}

.react-flow__node-custom .inner {
  background: var(--bg-color);
  padding: 16px 20px;
  border-radius: var(--node-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}

.react-flow__node-custom .icon {
  margin-right: 8px;
  margin-top: -4px;
}

.react-flow__node-custom .body {
  display: flex;
}

.react-flow__node-custom .title {
  font-size: 18px;
  margin-bottom: 2px;
  line-height: 1;
}

.react-flow__node-custom .subline {
  font-size: 14px;
  color: #777;
}

.react-flow__node-custom .cloud {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  display: flex;
  transform-origin: center center;
  padding: 2px;
  overflow: hidden;
  box-shadow: var(--node-box-shadow);
  z-index: 1;
}

.react-flow__node-custom .cloud div {
  background-color: var(--bg-color);
  flex-grow: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-flow__handle {
  opacity: 0;
}

.react-flow__handle.source {
  padding: 10px;
}

.react-flow__handle.target {
  padding: 10px;
}

.react-flow__node:focus {
  outline: none;
}

.react-flow__edge .react-flow__edge-path {
  stroke: #f69e1a;
  stroke-width: 2;
  stroke-opacity: 0.75;
}

.animated-edge .react-flow__edge-path {
  stroke: #f69e1a;
  animation: edgeFlow 4s ease-in-out infinite;
  filter: drop-shadow(0 0 3px rgba(186, 145, 83, 0.4));
  stroke-width: 2;
  stroke-opacity: 0.75;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 5;
  stroke-dashoffset: 0;
}

@keyframes edgeFlow {
  0% {
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 15;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dasharray: 25;
    stroke-dashoffset: -50;
  }
}
